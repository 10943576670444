<script lang="ts" setup>
import type { SetOptional } from 'type-fest'

import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { AuthType, Employee, PortalType, UserType } from '@/types/user'

import CreateEmployeeForm from '@/components/employees/CreateEmployeeForm.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'

const emit = defineEmits<{ (e: 'created', value?: void): void }>()
const props = defineProps<{ currentTab: PortalType; companyId?: uuid }>()

const { t } = useI18n()
const authStore = useAuthStore()
const open = ref(false)
const page = ref(0)
const newUser = ref(false)
const authType = ref(AuthType.PhoneNumber)
const {
    data: form,
    loading,
    submit,
    errors,
} = useForm<SetOptional<Employee, 'id' | 'appRoleName' | 'webRoleName'>>({
    id: undefined,
    phoneNumber: '',
    username: '',
    name: '',
    profileImage: undefined,
    email: '',
    userType: UserType.Driver,
    appRoleId: null,
    webRoleId: null,
    appEnabled: false,
    webportalEnabled: false,
    twoFactorEnabled: false,
    locale: 'en',
    permissions: {},
    locations: [],
    settlementTemplateId: null,
    employeeNumber: '',
})

async function checkAuth(auth: AuthType) {
    authType.value = auth
    try {
        const params: Record<string, string> = {}
        if (auth === AuthType.PhoneNumber) {
            params.phoneNumber = form.phoneNumber
        } else if (auth === AuthType.Username) {
            params.username = form.username
        }
        const response = await submit<ResourceResponse<Employee>>(
            'get',
            window.route('company.employees.check-auth', {
                company: props.companyId || authStore.companyId,
            }),
            { data: params },
        )
        if (response) {
            newUser.value = true
            form.id = response.data.id
            form.name = response.data.name
            form.username = response.data.username
            form.phoneNumber = response.data.phoneNumber
            form.profileImage = response.data.profileImage
            form.email = response.data.email
            form.twoFactorEnabled = response.data.twoFactorEnabled
            page.value = 1
        }
    } catch (e) {
        if (!axios.isAxiosError(e)) throw e

        if (e.response?.status === 409) {
            // TODO Open edit modal
            notify({ type: 'info', text: t('userAlreadyExists') })
        } else if (e.response?.status === 404) {
            newUser.value = false
            if (auth === AuthType.PhoneNumber) {
                form.twoFactorEnabled = true
            }
            page.value = 1
        }
    }
}

function reset() {
    page.value = 0
    form.id = undefined
    form.phoneNumber = ''
    form.username = ''
    form.name = ''
    form.profileImage = undefined
    form.email = ''
    form.userType = UserType.Driver
    form.appEnabled = false
    form.twoFactorEnabled = false
    form.webportalEnabled = props.currentTab === PortalType.Webportal
    form.appEnabled = props.currentTab === PortalType.App
    form.settlementTemplateId = null
}

function closeModal(created: boolean) {
    open.value = false
    if (created) {
        emit('created')
    }
}

watch(open, (value) => {
    if (value) reset()
})

provide('loading', loading)
</script>

<template>
    <MyModal v-model="open" :back-button="page === 1" @back-button-clicked="reset">
        <template #title>
            {{ t('createEmployee') }}
        </template>

        <LoaderWrapper :visible="loading" />

        <div v-if="page === 0">
            <MyForm
                :errors="errors"
                class="mt-5 flex space-x-2"
                @submit.prevent="checkAuth(AuthType.PhoneNumber)"
            >
                <MyPhoneInput v-model="form.phoneNumber" autofocus :label="t('phoneNumber')" />

                <MyButton class="mt-6" scheme="primary" v-text="t('next')" />
            </MyForm>

            <hr class="my-4" />

            <MyForm
                :errors="errors"
                class="flex space-x-2"
                @submit.prevent="checkAuth(AuthType.Username)"
            >
                <MyInput v-model="form.username" name="username" :label="t('username')" />

                <MyButton class="mt-6" scheme="primary" v-text="t('next')" />
            </MyForm>
        </div>

        <CreateEmployeeForm
            v-if="page === 1"
            :data="form"
            :auth-type="authType"
            :company-id="props.companyId"
            @created="closeModal(true)"
        />
    </MyModal>

    <MyButton :disabled="loading" plain scheme="primary" size="small" @click="open = true">
        <mdi:plus-thick class="mr-1" />
        {{ t('createNew') }}
    </MyButton>
</template>
