<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { computed, inject, onBeforeMount, provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { Customer, CustomerCompany, LicenseType, Location, LocationPoint } from '@/types/company'
import { PermissionType, ResourceResponse, uuid } from '@/types/general'
import { currentRouteModuleKey } from '@/types/global-injection-keys'
import { MyButtonScheme } from '@/types/layout/my-button'
import { currentCustomerKey, currentCustomerLocationKey } from '@/types/packaging'
import { image } from '@/utils/assets'
import { phoneNumberString } from '@/utils/string'
import { useCompanyStore } from '@/stores/company-store'
import Employees from '@/pages/settings/Employees.vue'

import CustomerSettingsModal from '@/components/customers/CustomerSettingsModal.vue'
import TransferCustomerDataModal from '@/components/customers/TransferCustomerDataModal.vue'
import UpdateCustomerCompanyModal from '@/components/customers/UpdateCustomerCompanyModal.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import ManageLocationModal from '@/components/locations/ManageLocationModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyMenuItem from '@/components/my-components/MyMenuItem.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import BalanceTable from '@/components/packaging/balance/BalanceTable.vue'
import CustomerBalanceLimitModal from '@/components/packaging/balance/CustomerBalanceLimitModal.vue'
import GenerateStatementModal from '@/components/packaging/balance/GenerateStatementModal.vue'
import ScheduledStatementModal from '@/components/packaging/balance/ScheduledStatementModal.vue'
import TransactionsTable from '@/components/packaging/transactions/TransactionsTable.vue'
import ManageTransaction from '@/components/packaging/transactions/manage/ManageTransaction.vue'
import ReceiptsTable from '@/components/pod/ReceiptsTable.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ActionRow from '@/components/table/internal/ActionRow.vue'
import ShipmentsTable from '@/components/delivery-management/ShipmentsTable.vue'
import TicketsTable from '@/components/delivery-management/TicketsTable.vue'
import MapComponent from '@/components/MapComponent.vue'

const { t } = useI18n()
const route = useRoute()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const confirm = useConfirm()
const currentRouteModule = inject(currentRouteModuleKey)!

const loading = ref(false)
const deletingLocation = ref(false)
const customer = ref<Customer<CustomerCompany>>()
const currentTab = ref('company')
const selectedLocationId = ref<uuid>()
const locationElements = ref<HTMLDivElement[]>([])
const createLocationModalOpen = ref(false)
const updateLocationModalOpen = ref(false)
const transferCustomerModalOpen = ref(false)
const updateCustomerCompanyModalOpen = ref(false)
const customerSettingsModalOpen = ref(false)
const statementModalOpen = ref(false)
const scheduledStatementModalOpen = ref(false)
const balanceLimitModalOpen = ref(false)

const hasPackaging = computed(() =>
    authStore.hasLicense([LicenseType.PackagingModule, LicenseType.PackagingModuleReadonly]),
)
const showAgentNumber = computed(() => authStore.hasLicense(LicenseType.AgentLocationFiltering))
const company = computed(() => customer.value?.customerCompany)
const canManageCustomer = computed(() => {
    return (
        authStore.hasPermission(PermissionType.ManageCustomers) &&
        !authStore.isReadonly(LicenseType.PackagingModuleReadonly)
    )
})
const ownCompanyLocations = computed(() => {
    const locations: Record<uuid, Location> = {}
    for (const location of companyStore.locations) {
        locations[location.id] = location
    }

    return locations
})
const lockedReason = computed<string | null>(() => {
    if (customer.value?.customerCompany.mypalletCustomer) {
        return t('lockedCustomerMyPalletCustomer')
    } else if (customer.value?.customerCompany.multipleClients) {
        return t('lockedCustomerMultipleClients')
    }

    return null
})
const selectedLocation = computed(() => {
    if (!selectedLocationId.value) return undefined

    return company.value?.locations?.find(({ id }) => id === selectedLocationId.value)
})

const selectedPoint = computed<LocationPoint>(() => {
    if (selectedLocation.value) return selectedLocation.value.location
    if (company.value) return company.value.location

    return { latitude: 0, longitude: 0 }
})

const locations = computed(() => {
    const locations = company?.value?.locations || []

    return locations.sort((a, b) => {
        if (locationActive(a) != locationActive(b)) {
            return locationActive(a) ? -1 : 1
        }

        return a.name > b.name ? 1 : -1
    })
})

const moduleTabs = computed(() => {
    const tabs: Record<string, string> = {}
    if (hasPackaging.value) {
        tabs['transactions'] = t('packagingTransactions')
    }
    if (authStore.hasLicense(LicenseType.PODModule)) {
        tabs['receipts'] = t('podReceipts')
    }
    if (authStore.hasLicense(LicenseType.DeliveryManagementModule)) {
        tabs['shipments'] = t('shipments')
        tabs['tickets'] = t('tickets')
    }
    if (company.value && customer.value?.options?.haulier) {
        tabs['appUsers'] = t('appUsers')
    }

    return tabs
})
const defaultModuleTab = computed(() => {
    if (currentRouteModule.value?.license === LicenseType.PackagingModule) {
        return 'transactions'
    } else if (currentRouteModule.value?.license === LicenseType.PODModule) {
        return 'receipts'
    } else if (currentRouteModule.value?.license === LicenseType.DeliveryManagementModule) {
        return 'shipments'
    } else {
        // We'll set the default to the first tab preset
        return Object.keys(moduleTabs.value)[0] ?? 'transactions'
    }
})
const reachedGeneralBalanceLimit = computed<boolean>(() => {
    const generalLimit = customer.value?.balanceLimits?.find((limit) => limit.productId === null)

    return (generalLimit?.reachedAt ?? null) !== null
})
const createLocationRoute = computed(() =>
    window.route('company.customers.locations.store', {
        company: authStore.companyId,
        customer: route.params.id.toString(),
    }),
)
const updateLocationRoute = computed(() =>
    window.route('company.customers.locations.update', {
        company: authStore.companyId,
        customer: route.params.id.toString(),
        location: selectedLocationId.value || '',
    }),
)
// We'll process these here to avoid doing it on every render
const phoneNumbers = computed(() => {
    return (company.value?.locations || []).map(({ phoneNumber }) =>
        phoneNumber ? phoneNumberString(phoneNumber) : '',
    )
})

const emails = computed(() =>
    [
        customer.value?.email ?? customer.value?.customerCompany.email,
        ...(customer.value?.additionalEmails || []),
    ].filter((email) => !!email),
)

function locationActive(location: Location): boolean {
    if (location.active) {
        return location.customerLocationActive ?? true
    }

    return location.active
}

async function fetch() {
    loading.value = true

    try {
        const { data: response } = await axios.get<ResourceResponse<Customer<CustomerCompany>>>(
            window.route('company.customers.show', {
                company: authStore.companyId,
                customer: route.params.id.toString(),
            }),
        )

        customer.value = response.data
    } finally {
        loading.value = false
    }
}

function selectLocation(id: string) {
    selectedLocationId.value = id
}

function updateLocation(locationId: string) {
    selectLocation(locationId)
    updateLocationModalOpen.value = true
}

function selectTab(tab: string) {
    currentTab.value = tab

    if (tab === 'company') {
        selectedLocationId.value = undefined
    } else if (company.value!.locations.length > 0) {
        selectLocation(company.value!.locations[0].id)
    }
}

function copy(email: string) {
    navigator.clipboard.writeText(email)
}

function scrollToLocation(locationId: string) {
    setTimeout(() => {
        const index = company.value?.locations?.findIndex(({ id }) => id === locationId)
        if (index)
            locationElements.value[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 150)
}

async function createLocationModalClosed(locationId?: string) {
    createLocationModalOpen.value = false
    if (!locationId) return

    await fetch()
    selectLocation(locationId)
    scrollToLocation(locationId)
}

async function updateLocationModalClosed(locationId?: string) {
    updateLocationModalOpen.value = false
    if (locationId) {
        await fetch()
        selectLocation(locationId)
    }
}

async function deleteLocation(locationId: string) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('location') }),
            t('deleteEntityDescription', { entity: t('location') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
        const url = window.route('company.customers.locations.destroy', {
            company: authStore.companyId,
            customer: route.params.id.toString(),
            location: locationId,
        })
        deletingLocation.value = true
        await axios.delete(url)
        selectedLocationId.value = locations.value[0]?.id
        await fetch()
        notify({ title: t('locationDeleted'), type: 'success' })
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 403) {
            notify({ title: t('ActionNotAllowed'), type: 'error' })
        } else if (e) {
            notify({ title: t('unknownError'), type: 'error' })
        }
    } finally {
        deletingLocation.value = false
    }
}

async function customerUpdated() {
    updateCustomerCompanyModalOpen.value = false
    await fetch()
}

async function customerSettingsUpdated() {
    customerSettingsModalOpen.value = false
    await fetch()
}

onBeforeMount(() => fetch())

watch(
    () => route.params.id,
    async () => {
        if (!route.params.id) return
        transferCustomerModalOpen.value = false
        await fetch()
    },
)

provide(currentCustomerKey, customer)
provide(currentCustomerLocationKey, selectedLocation)
</script>

<template>
    <CrumbsAndActions>
        <slot />
        <Breadcrumb current v-text="company?.name || t('company')" />

        <template v-if="customer && canManageCustomer" #actions>
            <div v-tooltip="{ disabled: lockedReason === null, content: lockedReason }">
                <MyButton
                    scheme="action"
                    size="small"
                    :disabled="lockedReason !== null"
                    @click="updateCustomerCompanyModalOpen = true"
                >
                    <mdi:pencil class="mr-1" />
                    {{ t('editCompany') }}
                </MyButton>
            </div>

            <MyButton scheme="action" size="small" @click="customerSettingsModalOpen = true">
                <mdi:cog class="mr-1" />
                {{ t('customerSettings') }}
            </MyButton>

            <ActionRow
                v-if="!company?.mypalletCustomer"
                menu-items-class="mt-3 z-30 right-0 w-[250px]"
            >
                <template #button>
                    <MyButton scheme="primary" plain size="small">
                        <mdi:dots-vertical />
                    </MyButton>
                </template>

                <ActionMenuItem @click="transferCustomerModalOpen = true">
                    <mdi:file-move class="mr-2" />
                    {{ t('transferCustomerData') }}
                </ActionMenuItem>
            </ActionRow>
        </template>
    </CrumbsAndActions>

    <UpdateCustomerCompanyModal
        v-if="customer && lockedReason === null"
        v-model="updateCustomerCompanyModalOpen"
        :customer="customer"
        @saved="customerUpdated"
    />

    <CustomerSettingsModal
        v-if="customer"
        v-model="customerSettingsModalOpen"
        :customer="customer"
        @saved="customerSettingsUpdated"
    />

    <ManageLocationModal
        v-model="createLocationModalOpen"
        for-customer
        :route="createLocationRoute"
        @close="createLocationModalClosed"
    />

    <ManageLocationModal
        v-model="updateLocationModalOpen"
        for-customer
        :unchangeable="
            customer?.customerCompany.mypalletCustomer || customer?.customerCompany.multipleClients
        "
        :route="updateLocationRoute"
        :location="selectedLocation"
        @close="updateLocationModalClosed"
    />

    <TransferCustomerDataModal
        v-if="customer"
        v-model="transferCustomerModalOpen"
        :customer="customer"
    />

    <CustomerBalanceLimitModal
        v-if="customer"
        v-model="balanceLimitModalOpen"
        :customer="customer"
        @updated="fetch()"
    />

    <GenerateStatementModal v-if="customer" v-model="statementModalOpen" :customer="customer" />

    <ScheduledStatementModal
        v-if="customer"
        v-model="scheduledStatementModalOpen"
        v-model:customer="customer"
    />

    <RouterView />

    <LoaderWrapper :visible="loading" />

    <section v-if="customer && company" class="flex flex-col gap-10 lg:grid lg:grid-cols-6">
        <MyPanel
            class="flex h-[550px] overflow-hidden rounded-xl"
            :panel-class="'lg:col-span-4' + (!hasPackaging ? ' lg:col-start-2' : '')"
            shadow
        >
            <LoaderWrapper :visible="deletingLocation" />

            <aside
                class="top-8 bottom-8 left-8 z-10 grid w-2/5 grid-rows-2 bg-primary-50 shadow-lg dark:bg-dark-500"
                style="grid-template-rows: auto 90%"
            >
                <header
                    class="relative flex space-x-1.5 bg-green-500 px-1.5 pt-1.5 text-primary-50"
                >
                    <span
                        v-for="tab in ['company', 'location']"
                        :key="'tab-' + tab"
                        :class="{
                            'pointer-events-none rounded-t-lg bg-primary-50 text-primary-500 dark:bg-dark-500 dark:text-green-500':
                                currentTab === tab,
                        }"
                        class="flex grow cursor-pointer items-center justify-center rounded-t-lg py-2 text-center text-sm font-semibold uppercase hover:bg-primary-100 hover:text-primary-500 dark:hover:bg-dark-600 dark:hover:text-green-500"
                        @click="selectTab(tab)"
                        v-text="t(tab)"
                    >
                    </span>
                </header>

                <!-- Company -->
                <section v-if="currentTab === 'company'" class="relative overflow-auto p-6">
                    <div
                        v-tooltip="{
                            disabled: lockedReason === null,
                            content: lockedReason,
                        }"
                    >
                        <MyButton
                            v-if="canManageCustomer"
                            class="absolute right-6 top-6"
                            icon
                            pill
                            plain
                            scheme="light"
                            size="small"
                            :disabled="lockedReason !== null"
                            @click="updateCustomerCompanyModalOpen = true"
                        >
                            <mdi:pencil />
                        </MyButton>
                    </div>

                    <img
                        :alt="company.name"
                        :src="
                            image(
                                company.logo ?? 'cf6c449d-282b-49e9-838e-eb395c168700',
                                'thumbnail',
                            )
                        "
                        class="mx-auto mb-3 h-24 w-auto rounded-full shadow-xl"
                    />
                    <div class="flex flex-col items-center">
                        <h1
                            class="mt-2 flex items-center justify-center text-lg font-semibold uppercase"
                        >
                            {{ company.name }}
                        </h1>
                        <div class="text-xs font-semibold uppercase text-white">
                            <div
                                v-if="customer.options.haulier"
                                v-tooltip="t('haulierExplanation')"
                                class="ml-2 mb-1 inline-block rounded-lg bg-green-600 py-1 px-2"
                                v-text="t('haulier')"
                            />
                            <div
                                v-if="!customer.active"
                                v-tooltip="t('deactivatedExplanation')"
                                class="ml-2 mb-1 inline-block rounded-lg bg-red-600 py-1 px-2"
                                v-text="t('deactivated')"
                            />
                        </div>
                    </div>

                    <div class="flex justify-center text-sm">
                        <div v-if="company.vat" class="flex items-center">
                            <span class="mr-2 font-semibold uppercase" v-text="t('vat')" />
                            {{ company.vat }}
                            <mdi:account
                                v-if="company.privateCustomer"
                                v-tooltip="t('privateCustomer')"
                                class="ml-1"
                            />
                        </div>

                        <div
                            v-else
                            class="ml-2 mb-1 inline-flex cursor-pointer items-center rounded-lg bg-red-600 py-1 px-2 text-white"
                            @click="updateCustomerCompanyModalOpen = true"
                        >
                            <mdi:exclamation />
                            {{ t('vatMissingClickToAdd') }}
                        </div>
                    </div>

                    <div class="my-3 border-t border-primary-200 pt-2 text-sm">
                        <h2 class="mb-2 text-xs font-semibold uppercase" v-text="t('address')" />
                        <p v-text="company.address" />
                        <p>
                            <span v-text="company.zipcode" />,
                            <span v-text="company.city" />
                        </p>
                        <p v-text="company.country" />
                    </div>

                    <div class="my-3 border-t border-primary-200 pt-2 text-sm">
                        <h2
                            class="mb-2 text-xs font-semibold uppercase"
                            v-text="t('contactInformation')"
                        />
                        <p v-text="customer.contactPerson || company.contactPerson" />
                        <p
                            v-if="customer.phoneNumber || company.phoneNumber"
                            v-text="phoneNumberString(customer.phoneNumber || company.phoneNumber)"
                        />
                        <p
                            v-for="email in emails"
                            :key="email"
                            class="flex group w-fit cursor-pointer active:text-primary-400"
                            @click="email && copy(email)"
                        >
                            <span>{{ email }}</span>
                            <mdi:content-copy
                                class="transition-opacity opacity-0 group-hover:opacity-100 ml-1"
                            />
                        </p>
                    </div>

                    <div class="my-2 border-t border-primary-200 pt-2 text-sm">
                        <div v-if="customer.notes[0]" class="break-all">
                            <b class="font-semibold uppercase" v-text="t('department')" />
                            {{ customer.notes[0] }}
                        </div>

                        <div v-if="customer.notes[1]" class="break-all">
                            <b class="font-semibold uppercase" v-text="t('remarks')" />
                            {{ customer.notes[1] }}
                        </div>

                        <div v-if="customer.accountNumber" class="break-all">
                            <b class="font-semibold uppercase" v-text="t('accountNumber')" />
                            {{ customer.accountNumber }}
                        </div>
                    </div>
                </section>

                <!-- Locations -->
                <section v-if="currentTab === 'location'" class="flex flex-col">
                    <div class="space-y-1.5 overflow-y-scroll p-3">
                        <div
                            v-for="(location, index) in locations"
                            :key="location.id"
                            ref="locationElements"
                            class="group relative"
                        >
                            <div
                                v-if="canManageCustomer"
                                class="absolute right-2 top-2 flex rounded-full bg-primary-200 opacity-0 transition group-hover:opacity-100"
                            >
                                <MyButton
                                    class="hover:bg-primary-300"
                                    icon
                                    pill
                                    plain
                                    size="small"
                                    @click="updateLocation(location.id)"
                                >
                                    <mdi:pencil />
                                </MyButton>
                                <div
                                    v-tooltip="{
                                        disabled: lockedReason === null,
                                        content: lockedReason,
                                    }"
                                >
                                    <MyButton
                                        class="hover:bg-red-500 hover:text-primary-200"
                                        icon
                                        pill
                                        plain
                                        size="small"
                                        :disabled="lockedReason !== null"
                                        @click="deleteLocation(location.id)"
                                    >
                                        <mdi:trash-can />
                                    </MyButton>
                                </div>
                            </div>
                            <div
                                :class="
                                    selectedLocation?.id === location.id
                                        ? 'cursor-default bg-primary-200 dark:bg-dark-400'
                                        : 'cursor-pointer'
                                "
                                class="rounded-lg p-3 transition group-hover:bg-primary-200 dark:group-hover:bg-dark-400"
                                @click="selectLocation(location.id)"
                            >
                                <div>
                                    <h2
                                        v-tooltip="{
                                            content: t('disabledLocationExplanation'),
                                            disabled: locationActive(location),
                                        }"
                                        class="inline font-semibold uppercase"
                                        :class="{ 'opacity-50': !locationActive(location) }"
                                        v-text="location.name"
                                    />
                                    <div
                                        v-if="!locationActive(location)"
                                        v-tooltip="t('disabledLocationExplanation')"
                                        class="ml-2 inline-block rounded-lg bg-red-600 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                                        v-text="t('inactive')"
                                    />
                                </div>

                                <div class="mt-2 flex justify-between text-sm">
                                    <p class="grow text-xs">
                                        {{ location.address }}
                                        <span class="block">
                                            {{ location.zipcode }} {{ location.city }}
                                        </span>
                                        <span class="block" v-text="location.country" />
                                    </p>

                                    <div class="mt-2 text-right text-xs lg:mt-0">
                                        <p v-if="location?.pNumber">
                                            <span
                                                class="font-semibold uppercase"
                                                v-text="t('pNumber')"
                                            />
                                            {{ location.pNumber }}
                                        </p>

                                        <p v-if="showAgentNumber && location?.agentLocationId">
                                            <span
                                                class="font-semibold uppercase"
                                                v-text="t('agentNumber')"
                                            />
                                            {{
                                                ownCompanyLocations[location.agentLocationId]
                                                    .agentNumber
                                            }}
                                        </p>

                                        <p v-if="location.email" v-text="location.email" />

                                        <p
                                            v-if="location.phoneNumber"
                                            v-text="phoneNumbers[index]"
                                        />
                                    </div>
                                </div>

                                <template v-if="location?.note">
                                    <p class="mt-2.5 block text-xs font-bold" v-text="t('note')" />
                                    <p class="border-primary-300 text-xs" v-text="location.note" />
                                </template>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="!customer.customerCompany.mypalletCustomer && canManageCustomer"
                        class="w-full p-3"
                    >
                        <div
                            class="cursor-pointer rounded-lg bg-primary-200 p-3 text-center text-sm font-black uppercase text-primary-400 transition hover:bg-primary-400 hover:text-primary-50"
                            @click="createLocationModalOpen = true"
                            v-text="t('createLocation')"
                        />
                    </div>
                </section>
            </aside>
            <MapComponent
                :markers="[{ location: selectedPoint }]"
                :pan-to="selectedPoint"
                class="h-full grow"
            />
        </MyPanel>

        <MyPanel v-if="hasPackaging" bordered invisible panel-class="lg:col-span-2">
            <template #header>
                <div class="flex items-center">
                    <h3
                        class="relative py-4 pl-6 font-bold uppercase tracking-wide"
                        v-text="t('balance')"
                    />

                    <mdi:alarm
                        v-if="reachedGeneralBalanceLimit"
                        v-tooltip="t('customerReachedBalanceLimit')"
                        class="ml-3 inline-block text-red-500"
                    />
                </div>
            </template>

            <template #actions>
                <MyMenuItem
                    v-if="authStore.hasPermission(PermissionType.CreateBankStatements)"
                    @click="statementModalOpen = true"
                >
                    <mdi:document class="mr-2" />
                    {{ t('generateStatement') }}
                </MyMenuItem>

                <MyMenuItem
                    v-if="authStore.hasPermission(PermissionType.CreateBankStatements)"
                    @click="scheduledStatementModalOpen = true"
                >
                    <mdi:rotate-360 class="mr-2" />
                    {{ t('automaticStatements') }}
                </MyMenuItem>

                <MyMenuItem
                    v-if="authStore.hasPermission(PermissionType.ManageCustomers)"
                    @click="balanceLimitModalOpen = true"
                >
                    <mdi:credit-card-remove-outline class="mr-2" />
                    {{ t('configureBalanceLimts') }}
                </MyMenuItem>
            </template>

            <BalanceTable
                :customer-company-id="customer.customerCompany.id"
                :receiver-location-id="selectedLocation?.id"
                :balance-limits="customer.balanceLimits"
            />
        </MyPanel>
    </section>

    <MyTabs
        v-if="customer && Object.keys(moduleTabs).length > 0"
        v-slot="{ tabKey }"
        :tabs="moduleTabs"
        :default-tab="defaultModuleTab"
        shadow
        panel-class="mt-14"
    >
        <template v-if="tabKey === 'transactions'">
            <ManageTransaction
                v-if="authStore.hasLicense(LicenseType.BackendTransactions)"
                no-button
            />

            <TransactionsTable :customer-company-id="customer?.customerCompany.id" />
        </template>

        <ReceiptsTable
            v-if="tabKey === 'receipts'"
            :customer-company-id="customer?.customerCompany.id"
        />

        <Employees
            v-if="tabKey === 'appUsers' && company"
            :company-id="company.id"
            minimal-header
            only-app-users
            :readonly="company.mypalletCustomer"
        />

        <ShipmentsTable
            v-if="tabKey === 'shipments' && company"
            :customer-company-id="company.id"
        />

        <TicketsTable v-if="tabKey === 'tickets' && company" :customer-company-id="company.id" />
    </MyTabs>

    <h1
        v-if="!loading && !customer"
        class="my-auto text-center text-4xl font-bold"
        v-text="t('customerNotFound')"
    />
</template>
