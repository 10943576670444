<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { DropdownStringOption } from '@/types/inputs'
import { MinimalCompanyTag } from '@/types/delivery-management'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

interface Form {
    email: string
    dateRange: Date[]
    tags: uuid[]
    customerCompanyId: uuid | null
}

interface Props {
    customerCompanyId?: uuid
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()

const open = ref(false)
const tags = useLazyLoadedList<DropdownStringOption>(fetchTags)
const { data, submit, errors, reset, loading } = useForm<Form>({
    email: authStore.user?.email ?? '',
    dateRange: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
    tags: [],
    customerCompanyId: props.customerCompanyId ?? null,
})

async function fetchTags(): Promise<DropdownStringOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', { company: authStore.companyId }),
    )

    return response.data.data.map((tag) => ({ value: tag.id, label: t(tag.name) }))
}

async function startExport() {
    const response = await submit(
        'post',
        window.route('dm.company.tickets.export', {
            company: authStore.companyId,
        }),
    )

    if (response) {
        open.value = false
        notify({ type: 'success', text: t('exportStarted') })
    }
}

watch(open, () => {
    if (!open.value) reset()
})
</script>

<template>
    <MyModal v-model="open">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <template #title>
            {{ t('exportModel', { model: t('tickets') }) }}
        </template>

        <MyForm class="flex flex-col space-y-4" :errors="errors" @submit.prevent="startExport">
            <MyInput v-model="data.email" :label="t('email')" name="email" autofocus />

            <div>
                <MyInputLabel>{{ t('date') }}</MyInputLabel>

                <Datepicker
                    v-model="data.dateRange"
                    name="dateRange"
                    class="w-full"
                    input-class-name="w-full !text-center"
                    range
                    close-on-scroll
                    auto-apply
                    :enable-time-picker="false"
                    format="yyyy-MM-dd"
                    :transitions="false"
                    :placeholder="t('allTime')"
                    :config="{ closeOnAutoApply: true }"
                    utc
                    :start-time="[
                        { hours: 0, minutes: 0 },
                        { hours: 23, minutes: 59 },
                    ]"
                />
            </div>

            <MySelect
                v-model="data.tags"
                :label="t('tags')"
                :options="tags.items.value"
                name="tags"
                multiple
                clear-button
                :placeholder="t('allTags')"
                :loading="tags.fetching.value"
                @focus="tags.fetch()"
            />

            <div class="mt-4 flex justify-end">
                <MyButton scheme="primary" v-text="t('startExport')" />
            </div>
        </MyForm>
    </MyModal>

    <slot>
        <MyButton scheme="action" size="small" @click="open = true">
            <mdi:export class="mr-1" />
            {{ t('export') }}
        </MyButton>
    </slot>
</template>
