import type { Except } from 'type-fest'

import { Model, PermissionType, uuid } from '@/types/general'
import { CustomerBalanceLimit, ScheduledStatementOptions } from '@/types/packaging'
import { LocationType } from '@/types/transaction'

export interface LocationPoint {
    latitude: number
    longitude: number
}

export enum LicenseType {
    Stock = 1,
    LocationTrade = 8,
    BackendTransactions = 9,
    TransactionDrafts = 10,
    PackagingModule = 15,
    DamageReportModule = 16,
    PODModule = 20,
    DeliveryManagementModule = 22,
    CustomerBalanceInApp = 23,
    ECMRModule = 26,
    ActiveWebTrade = 27,
    TrailerTelemetry = 30,
    DriverReportModule = 31,
    Filled = 32,
    PoliceReport = 33,
    TrailerVinScanning = 34,
    GarageModule = 37,
    FjordlineTrailerInformation = 41,
    MultipleTransactionDrafts = 42,
    PackagingModuleReadonly = 43,
    TransportObjectOwnerCompany = 44,
    AgentLocationFiltering = 45,
    PrivateCustomers = 46,
    IsolatedShipmentScanning = 47,
    PodPdfGeneration = 48,
    ShipmentDeliveryDescriptions = 49,
    DeliveryManagementTourOptimization = 50,
    DeliveryManagementTourETA = 51,
    DeliveryManagementNavigation = 52,
}

export interface Company extends Model {
    id: uuid
    name: string
    vat: string
    mypalletCustomer: boolean
    location: LocationPoint
    contactPerson: string
    email: string
    phoneNumber: string
    address: string
    logo?: string
    city: string
    country: string
    zipcode: string
    disabled: boolean
    privateCustomer: boolean
    locations: Location[]
}

export interface OwnCompany extends Except<Company, 'locations'> {
    clients: IncludedCompany[]
    options: CompanyOptions
}

export interface CompanyOptions {
    currency: string
    timezone?: string
    print?: boolean
    dashboardDisabled?: boolean
    hideDeliveryDeviations?: boolean
}

export interface CustomerCompany extends Company {
    multipleClients: boolean
}

// TODO: Please get a better name
export interface IncludedCompany extends Except<Company, 'locations'> {
    id: uuid
}

export interface MinimalCompany {
    id: uuid
    name: string
    vat: string
    options: CompanyOptions
}

export interface UserCompanyPermissions {
    readonly?: boolean
}

export interface UserCompany extends MinimalCompany {
    permissions: PermissionType[]
    locations: uuid[]
}

export interface Customer<CompanyModel = Company> extends Model {
    id: string
    customerCompany: CompanyModel
    contactPerson: string
    phoneNumber: string
    email: string
    additionalEmails: string[]
    phone: string
    type: number
    accountNumber: string | null
    moduleInfo: CustomerModuleInfo
    balanceLimits?: CustomerBalanceLimit[]
    reachedBalanceLimit?: boolean
    notes: string[]
    options: CustomerOptions
    active: boolean
}

export interface CustomerOptions extends Record<string, unknown> {
    haulier?: boolean
    sendHaulierDriverReports?: boolean
}

export interface Location {
    id: uuid
    companyId: uuid
    address: string
    city: string
    zipcode: string
    country: string
    email: string
    location: LocationPoint
    name: string
    phoneNumber: string
    type: LocationType
    active: boolean
    customerLocationActive?: boolean
    activeTrade: boolean
    pNumber: string
    note: string
    agentNumber: string
    agentLocationId?: uuid | null
}

export interface MinimalLocation {
    id: uuid
    name: string
    companyName: string
    address: string
    city: string
    zipcode: string
    country: string
}

export interface CustomerModuleInfo {
    packagingBalance?: number
    packagingBalancePrice?: number
    scheduledStatement?: ScheduledStatementOptions
}

export interface WebRole {
    id: number
    name: string
    permissions: PermissionType[]
}

export interface CanvasDrawingRef {
    saveCanvasJSON(): FabricDrawing

    saveCanvasToCloudFlare(): Promise<string>
}

export interface FabricDrawing {
    version: string
    objects: Record<string, unknown>[]
}

export interface CustomerLocation {
    id: uuid
    name: string
    address: string
    city: string
    zipcode: string
    country: string
    location: LocationPoint
    pNumber: string
    companyName: string
}
